import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { map } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';

import { tOrdinalItem } from '@components/i18n';
import { selectCurrentUser } from '@components/Auth';
import { StepFormProps } from '@components/Base';

import { CardHeader } from './card-header';
import { ContactFormControl } from './contact-form-control';
import { FormValues } from './schema';

const ContactStepForm = ({ onPrev }: StepFormProps) => {
  const { t } = useTranslation();
  const currentUser = useSelector(selectCurrentUser);
  const { values } = useFormikContext<FormValues>();

  return (
    <section>
      <Card className="shadow">
        <CardHeader onClick={() => onPrev()} title={t('healthQuote.memberDetails.title')} />
        <Card.Body>
          <ContactFormControl member="me" title={t('common:me')} disabled={!!currentUser?.dob} />

          {values.spouse.checked && <ContactFormControl member="spouse" title={t('common:spouse')} />}

          {map(values.sons, (_, idx) => (
            <ContactFormControl
              key={`sons-${idx}`}
              member={`sons.${idx}`}
              title={tOrdinalItem(idx + 1, 'common:son')}
            />
          ))}

          {map(values.daughters, (_, idx) => (
            <ContactFormControl
              key={`daughters-${idx}`}
              member={`daughters.${idx}`}
              title={tOrdinalItem(idx + 1, 'common:daughter')}
            />
          ))}
        </Card.Body>
        <Card.Footer>
          <div className="d-grid">
            <Button type="submit" size="lg">
              {t('common:getQuote')}
            </Button>
          </div>
        </Card.Footer>
      </Card>
    </section>
  );
};

export { ContactStepForm };
