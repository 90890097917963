import humps from 'lodash-humps-ts';
import { snakes } from '@utils/lodash';
import { authAxios } from '@components/Auth';
import { CarQuote } from '@models/CarQuote';
import { API_V1_BASE } from '@config';
import { CarPlanTypes } from '@models/CarPlanType';
import { NumericString } from '@utils/typescript';

export type GetAllCarQuoteParams = {
  carId: number;
  manufacturedYear: number;
  planType: CarPlanTypes;
  idvAmount?: number;
  accidentToDriver?: boolean;
  passengerLiability?: boolean;
  promotionCode?: string;
  carPlanIds?: number[];
};

export type GetCarQuoteParams = Omit<GetAllCarQuoteParams, 'carPlanIds'> & {
  carPlanId: number;
};

export type SendEmailCarQuoteParams = GetCarQuoteParams & {
  firstName: string;
  lastName: string;
  email: string;
};

export type CarQuoteUserParams = {
  firstName: string;
  firstNameKm?: string;
  lastName: string;
  lastNameKm?: string;
  gender: string;
  dob: string;
  personalCode?: string;
  occupation: string;
  email: string;
  mobileNumber: string;
  address: string;
};

export type CarQuoteVehicleParams = {
  engineNumber: string;
  chassisNumber: string;
  registrationPlace: string;
  registrationNumber: string;
};

export type CreateCarQuoteParams = GetCarQuoteParams &
  CarQuoteUserParams &
  CarQuoteVehicleParams & {
    activationDate: string;
  };

export type EditCarQuoteParams = {
  carOrderId: number;
};

export type UpdateCarQuoteParams = EditCarQuoteParams &
  CarQuoteVehicleParams & {
    activationDate: string;
  };

export type CustomCarQuoteParams = EditCarQuoteParams & {
  thirdPartyAmount: NumericString;
  thirdPartyDiscountAmount: NumericString;

  thirdPartyDeductibleAmount: NumericString;
  thirdPartyDeductibleDiscountAmount: NumericString;

  ownDamageAmount: NumericString;
  ownDamageDiscountAmount: NumericString;

  ownDamageDeductibleAmount: NumericString;
  ownDamageDeductibleDiscountAmount: NumericString;

  theftDeductibleAmount: NumericString;
  theftDeductibleDiscountAmount: NumericString;

  accidentToDriverAmount: NumericString;
  accidentToDriverDiscountAmount: NumericString;

  passengerLiabilityAmount: NumericString;
  passengerLiabilityDiscountAmount: NumericString;

  adminFeeAmount: NumericString;
  premium: NumericString;
  noClaimDiscountPercentage: NumericString;
  loyaltyDiscountPercentage: NumericString;
  customType: string;
};

export type LockCarQuoteParams = EditCarQuoteParams & {
  activationDate: string;
};

export type PayByCashCarQuoteParams = EditCarQuoteParams;

export class CarQuoteApi {
  static getAll = async (params: GetAllCarQuoteParams) => {
    const { carPlanIds, ...rest } = params;

    return await authAxios
      .get(`${API_V1_BASE}/car_quotes`, { params: { ...snakes(rest), car_plan_ids: carPlanIds } })
      .then((response) => humps(response.data) as CarQuote[]);
  };

  static get = async (params: GetCarQuoteParams) => {
    return await authAxios
      .get(`${API_V1_BASE}/car_quotes/find`, { params: snakes(params) })
      .then((response) => humps(response.data) as CarQuote);
  };

  static sendEmail = async (params: SendEmailCarQuoteParams) => {
    return await authAxios.post(`${API_V1_BASE}/car_quotes/send_email`, snakes(params));
  };

  static create = async (params: CreateCarQuoteParams) => {
    return await authAxios
      .post(`${API_V1_BASE}/user/car_quotes`, snakes(params))
      .then((response) => humps(response.data) as CarQuote);
  };

  static edit = async ({ carOrderId }: EditCarQuoteParams) => {
    return await authAxios
      .get(`${API_V1_BASE}/user/car_quotes/${carOrderId}`)
      .then((response) => humps(response.data) as CarQuote);
  };

  static update = async (params: UpdateCarQuoteParams) => {
    const { carOrderId, ...updateParams } = params;

    return await authAxios
      .put(`${API_V1_BASE}/user/car_quotes/${carOrderId}`, snakes(updateParams))
      .then((response) => humps(response.data) as CarQuote);
  };

  static custom = async (params: CustomCarQuoteParams) => {
    const { carOrderId, ...customParams } = params;

    return await authAxios
      .put(`${API_V1_BASE}/user/car_quotes/${carOrderId}/custom`, snakes(customParams))
      .then((response) => humps(response.data) as CarQuote);
  };

  static lock = async (params: LockCarQuoteParams) => {
    const { carOrderId, ...lockParams } = params;

    return await authAxios
      .put(`${API_V1_BASE}/user/car_quotes/${carOrderId}/lock`, snakes(lockParams))
      .then((response) => humps(response.data) as CarQuote);
  };

  static payByCash = async (params: PayByCashCarQuoteParams) => {
    const { carOrderId, ...payByCashParams } = params;

    return await authAxios
      .put(`${API_V1_BASE}/user/car_quotes/${carOrderId}/pay_by_cash`, snakes(payByCashParams))
      .then((response) => humps(response.data) as CarQuote);
  };
}
