import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { merge } from 'lodash';
import { AnySchema } from 'yup';
import { parse } from 'qs';

export const useParseSearchParams = <T>(defaultValues?: T): T => {
  const [searchParams] = useSearchParams();

  return useMemo(() => {
    return merge(defaultValues, parse(searchParams.toString()));
  }, [searchParams, defaultValues]);
};

export const useParseParams = <T>(schema: AnySchema) => {
  const [searchParams] = useSearchParams();

  return useMemo(() => {
    const values = parse(searchParams.toString());
    return schema.cast(values, { stripUnknown: true, assert: false }) as T;
  }, [searchParams, schema]);
};
