import React from 'react';
import { Routes, Route, Navigate, useNavigate, useLocation, matchPath } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { omit } from 'lodash';

import { FormBodyProps, useActiveStepIdx, useFormBody } from '@components/Base';
import { getHealthQuoteResultPageRoute, HealthQuoteResultPageParams } from '@components/HealthQuoteResultPage/route';
import { getPersonalAccidentQuoteResultPageRoute } from '@components/PersonalAccidentQuoteResultPage/route';
import { getCancerQuoteResultPageRoute } from '@components/CancerQuoteResultPage/route';

import { MembersStepForm } from './members-step-form';
import { ContactStepForm } from './contact-step-form';
import { FormValues } from './schema';
import { useInitialValues, useSteps } from './hook';

const FormBody = ({ activeStepIdx, steps }: FormBodyProps) => {
  const { handleNext, handlePrev } = useFormBody({ steps, activeStepIdx });

  return (
    <Routes>
      <Route index element={<MembersStepForm onNext={handleNext} />} />
      <Route path="members" element={<MembersStepForm onNext={handleNext} />} />
      <Route path="contact" element={<ContactStepForm onPrev={handlePrev} />} />
      <Route path="*" element={<Navigate to="" />} />
    </Routes>
  );
};

const cleanupValues = ({ me, spouse, sons, daughters }: FormValues) => {
  return {
    me: omit(me, 'checked'),
    spouse: spouse.checked ? omit(spouse, 'checked') : undefined,
    sons,
    daughters,
  } as HealthQuoteResultPageParams;
};

const HealthQuoteForm = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const steps = useSteps();
  const activeStepIdx = useActiveStepIdx(steps);
  const initialValues = useInitialValues();

  const handleSubmit = (values: FormValues) => {
    setTimeout(() => {
      if (matchPath('/health-insurance/*', pathname)) {
        navigate(getHealthQuoteResultPageRoute(cleanupValues(values)));
      } else if (matchPath('/personal-accident-insurance/*', pathname)) {
        navigate(getPersonalAccidentQuoteResultPageRoute(cleanupValues(values)));
      } else {
        navigate(getCancerQuoteResultPageRoute(cleanupValues(values)));
      }
    }, 1);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={steps[activeStepIdx].validationSchema}
      onSubmit={handleSubmit}
    >
      <Form>
        <FormBody activeStepIdx={activeStepIdx} steps={steps} />
      </Form>
    </Formik>
  );
};

export { HealthQuoteForm };
