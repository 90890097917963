import React, { useCallback } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Formik, Form, FormikHelpers } from 'formik';

import { FormBodyProps, useActiveStepIdx, useFormBody } from '@components/Base';

import { ContactStepForm } from './contact-step-form';
import QuestionForm from './QuestionForm';
import { FormValues } from './schema';
import { useSteps } from './hook';
import { isUndefined } from 'lodash';

const FormBody = ({ steps, activeStepIdx }: FormBodyProps) => {
  const { handleNext, handlePrev } = useFormBody({ steps, activeStepIdx });

  return (
    <Routes>
      <Route index element={<ContactStepForm onNext={handleNext} />} />
      <Route path="contact" element={<ContactStepForm onNext={handleNext} />} />
      <Route path="question" element={<QuestionForm onPrev={handlePrev} />} />
      <Route path="*" element={<Navigate to="" />} />
    </Routes>
  );
};

type Props = {
  initialValues: FormValues;
  onSubmit: (values: FormValues) => void;
};

const HealthQuoteBuyForm = ({ initialValues, onSubmit }: Props) => {
  const steps = useSteps();
  const activeStepIdx = useActiveStepIdx(steps);

  const handleSubmit = useCallback((values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    onSubmit(values);
    setSubmitting(false);
  }, []);

  if (isUndefined(steps)) return null;

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={steps[activeStepIdx].validationSchema}
      onSubmit={handleSubmit}
    >
      <Form>
        <FormBody activeStepIdx={activeStepIdx} steps={steps} />
      </Form>
    </Formik>
  );
};

export { HealthQuoteBuyForm };
