import { number, object, string } from 'yup';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { isLoggedIn } from 'axios-jwt';

import { useParseParams } from '@hooks/search-params';
import { StepType } from '@components/Base';
import { selectCurrentUser } from '@components/Auth';

import { FormValues } from './type';
import { useSelector } from 'react-redux';

export const useFormValuesParamsSchema = () => {
  let schema = object({
    carBrandId: number(),
    carModelId: number(),
    carSubModelId: number(),
    engineCapacity: number(),
    manufacturedYear: number(),
  });

  if (!isLoggedIn()) {
    schema = schema.concat(
      object({
        mobileNumber: string().default(''),
        fullName: string().default(''),
      })
    );
  }

  return schema;
}

export const useInitialValues = () => {
  const formValuesParamsSchema = useFormValuesParamsSchema();
  return useParseParams<FormValues>(formValuesParamsSchema);
}

export const useSteps = () => {
  const { t } = useTranslation();

  let steps: StepType[] = [
    {
      pathname: `car-brand`,
      validationSchema: object({
        carBrandId: number().required(),
      }),
    },
    {
      pathname: `car-model`,
      validationSchema: object({
        carModelId: number().required(),
      }),
    },
    {
      pathname: `car-variant`,
      validationSchema: object({
        carSubModelId: number().required(),
        engineCapacity: number().required(),
      }),
    },
    {
      pathname: `manufactured-year`,
      validationSchema: object({
        manufacturedYear: number().required(),
      }),
    },
  ];

  if (!isLoggedIn()) {
    steps = [
      ...steps,
      {
        pathname: `user`,
        validationSchema: object({
          mobileNumber: string().required(t('validation:required')).phone('KH'),
          fullName: string().required(t('validation:required')),
        }),
      },
    ]
  }

  return steps;
};
