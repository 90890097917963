import { AnySchema } from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { findIndex, last, range, some } from 'lodash';
import { useEffect, useMemo } from 'react';
import { stringify } from 'qs';
import { useFormikContext } from 'formik';

export type StepFormProps = {
  onNext: (values?: object) => void;
  onPrev: (values?: object) => void;
};

export type StepType = {
  pathname: string;
  validationSchema: AnySchema;
};

export type FormBodyProps = {
  activeStepIdx: number;
  steps: StepType[];
};

export const useActiveStepIdx = (steps: StepType[]) => {
  const { pathname } = useLocation();
  const activePathname = last(pathname.split('/'));

  return useMemo(() => {
    const idx = findIndex(steps, (options) => options.pathname === activePathname);
    return idx === -1 ? 0 : idx;
  }, [steps, activePathname]);
};

export const useFormBody = <T extends object>({ steps, activeStepIdx }: FormBodyProps) => {
  const navigate = useNavigate();
  const { initialValues, isValid, validateForm, values, setTouched, setValues, submitForm } = useFormikContext<T>();

  const handleNext = (partialValues?: Partial<T>) => {
    const nextStep = steps[activeStepIdx + 1];

    if (partialValues) {
      setValues({ ...values, ...partialValues });
    }

    if (isValid || partialValues) {
      setTouched({});

      if (nextStep) {
        navigate({
          pathname: nextStep.pathname,
          search: stringify({ ...values, ...partialValues }),
        });
      } else {
        setTimeout(() => {
          submitForm();
        }, 1);
      }
    }
  };

  const handlePrev = (partialValues?: Partial<T>) => {
    const prevStep = steps[activeStepIdx - 1];

    if (partialValues) {
      setValues({ ...values, ...partialValues });
    }

    if (prevStep) {
      navigate(
        {
          pathname: prevStep.pathname,
          search: stringify({ ...values, ...partialValues }),
        },
        { replace: true },
      );
    }
  };

  useEffect(() => {
    (async () => {
      await validateForm();
    })();
  }, [values]);

  useEffect(() => {
    some(range(activeStepIdx), (idx) => {
      const { pathname, validationSchema } = steps[idx];

      if (validationSchema.isValidSync(initialValues)) return false;

      navigate(
        {
          pathname,
          search: stringify(initialValues),
        },
        { replace: true },
      );

      return true;
    });
  }, [initialValues]);

  return { handleNext, handlePrev };
};
