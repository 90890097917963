import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Container } from 'react-bootstrap';

import HeaderSecondary from '@components/HeaderSecondary';
import { QuoteSupportCard } from '@components/Feature/Support/quote-support-card';

import ComparableCarQuote from './ComparableCarQuote';
import SubHeader from './SubHeader';
import CarQuoteList from './CarQuoteList';
import FilterSection from './FilterSection';
import FilterProvider from './FilterProvider';
import { stateReset } from './action';

const CarQuoteResultPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(stateReset());
  }, []);

  return (
    <>
      <HeaderSecondary />
      <section className="py-3">
        <SubHeader />
      </section>
      <FilterProvider>
        <section className="py-3">
          <FilterSection />
        </section>
        <section className="py-3">
          <Container>
            <QuoteSupportCard />
          </Container>
        </section>
        <CarQuoteList />
        <ComparableCarQuote />
      </FilterProvider>
    </>
  );
};

export { CarQuoteResultPage };
