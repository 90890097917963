import React, { useCallback, useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEmpty, isNull, isUndefined } from 'lodash';
import classNames from 'classnames';
import { useFormikContext } from 'formik';

import { CarVariant, getCarVariantName } from '@models/CarVariant';
import { Expand, NoData, Loading, StepFormProps } from '@components/Base';

import { CarVariantsFetchPayload } from '../type';
import { selectCarQuotePage } from '../selector';
import { FormValues } from './type';
import { carVariantsFetch } from '../action';
import { Item } from './item';
import { CardHeader } from './card-header';

const CarVariantStepForm = ({ onPrev, onNext }: StepFormProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { values } = useFormikContext<FormValues>();
  const { carModel, carVariantGroups } = useSelector(selectCarQuotePage);
  const carVariants = carVariantGroups[values.carModelId];
  const [expanded, setExpanded] = useState(false);
  const isLoading = isUndefined(carVariants);
  const isError = isNull(carVariants);
  const isReady = !isLoading && !isError;
  const hasCarVariants = !isEmpty(carVariants);

  const handleItemClick = useCallback(({ carSubModelId, engineCapacity }: CarVariant) => {
    onNext({ carSubModelId, engineCapacity });
  }, []);

  const renderItem = useCallback((carVariant: CarVariant) => {
    const { carSubModelId, engineCapacity } = carVariant;
    const key = `${carSubModelId}-${engineCapacity}`;
    const selected = values.carSubModelId == carSubModelId && values.engineCapacity == engineCapacity;

    return (
      <Col xs={12} sm={6} md={12} lg={6} xl={4} key={key} className="mb-3">
        <Item
          testID={classNames('car-variant-item', { selected })}
          selected={selected}
          onClick={() => handleItemClick(carVariant)}
        >
          {getCarVariantName(carVariant)}
        </Item>
      </Col>
    );
  }, []);

  useEffect(() => {
    if (isLoading) {
      const payload: CarVariantsFetchPayload = { carModelId: values.carModelId };
      dispatch(carVariantsFetch(payload));
    }

    if (values.carSubModelId && values.engineCapacity) {
      setExpanded(true);
    }
  }, []);

  return (
    <Card>
      <CardHeader
        title={t('carInsuranceQuote.carSubModel.title', { carModelName: carModel?.name })}
        onClick={() => onPrev({ carSubModelId: undefined, engineCapacity: undefined })}
      />
      <Card.Body>
        {isLoading && <Loading />}
        {isReady && !hasCarVariants && <NoData />}
        {isReady && hasCarVariants && (
          <Expand
            expand={expanded}
            wrapper={Row}
            wrapperProps={{ className: 'mb-n3' }}
            items={carVariants}
            renderItem={renderItem}
            onExpand={() => setExpanded(true)}
          />
        )}
      </Card.Body>
    </Card>
  );
};

export { CarVariantStepForm };
