import React from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { CarQuote } from '@models/CarQuote';
import { formatDate, formatDateTime } from '@utils/datetime';
import { OrderBadge } from '@components/Base';
import { selectIsCustomer } from '@components/Auth';

const OrderCardBody = ({
  activationDate,
  startedAt,
  endedAt,
  status,
  policyNumber,
  frontlineUser,
  customType,
}: CarQuote) => {
  const { t } = useTranslation();
  const isCustomer = useSelector(selectIsCustomer);
  const shouldRenderPeriod = !!startedAt && !!endedAt;

  return (
    <Card.Body>
      <Card.Title>{t('common:policyDetails')}</Card.Title>
      <ul className="list-unstyled mb-0">
        {policyNumber && (
          <li>
            {t('common:policyNumber')}: {policyNumber}
          </li>
        )}

        {shouldRenderPeriod ? (
          <li>
            {t('common:periodOfInsurance')}: {`${formatDateTime(startedAt)} - ${formatDateTime(endedAt)}`}
          </li>
        ) : (
          <li>
            {t('car.activationDate')}: {formatDate(activationDate) || t('common:instance')}
          </li>
        )}

        <li>
          {t('common:status')}: <OrderBadge status={status} />
        </li>

        {frontlineUser && (
          <li>
            {t('common:frontlineUser')}: {frontlineUser.fullName}
          </li>
        )}

        {!isCustomer && <li>Custom type: {customType}</li>}
      </ul>
    </Card.Body>
  );
};

export { OrderCardBody };
