import { object, boolean } from 'yup';

export const useInitialValues = () => {
  return {
    agree: false,
  };
};

export const useValidationSchema = () => {
  return object({
    agree: boolean().oneOf([true], 'You must agree'),
  });
};
