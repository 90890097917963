import React, { useCallback, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Form, Formik, useFormikContext } from 'formik';
import { pick } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { FormBodyProps, useActiveStepIdx, useFormBody } from '@components/Base';

import { carBrandFetch, carFetch, carModelFetch, carVariantFetch } from '../action';
import { CarFetchPayload, CarBrandFetchPayload, CarModelFetchPayload, CarVariantFetchPayload } from '../type';
import { selectCarQuotePage } from '../selector';
import { FormValues } from './type';
import { useSteps, useInitialValues } from './hook';
import { CarBrandStepForm } from './car-brand-step-form';
import { CarModelStepForm } from './car-model-step-form';
import { CarVariantStepForm } from './car-variant-step-form';
import { ManufacturedYearStepForm } from './manufactured-year-step-form';
import { UserStepForm } from './user-step-form';

const FormBody = ({ activeStepIdx, steps }: FormBodyProps) => {
  const dispatch = useDispatch();
  const { handleNext, handlePrev } = useFormBody({ steps, activeStepIdx });
  const { values } = useFormikContext<FormValues>();
  const { carBrand, carModel } = useSelector(selectCarQuotePage);

  useEffect(() => {
    if (values.carBrandId && values.carBrandId !== carBrand?.id) {
      const payload: CarBrandFetchPayload = { id: values.carBrandId };
      dispatch(carBrandFetch(payload));
    }

    if (values.carModelId && values.carModelId !== carModel?.id) {
      const payload: CarModelFetchPayload = { id: values.carModelId };
      dispatch(carModelFetch(payload));
    }

    if (values.carSubModelId && values.engineCapacity) {
      const payload: CarVariantFetchPayload = pick(values, ['carSubModelId', 'engineCapacity']);
      dispatch(carVariantFetch(payload));
    }
  }, []);

  return (
    <Routes>
      <Route index element={<CarBrandStepForm onNext={handleNext} />} />
      <Route path="car-brand" element={<CarBrandStepForm onNext={handleNext} />} />
      <Route path="car-model" element={<CarModelStepForm onPrev={handlePrev} onNext={handleNext} />} />
      <Route path="car-variant" element={<CarVariantStepForm onPrev={handlePrev} onNext={handleNext} />} />
      <Route path="manufactured-year" element={<ManufacturedYearStepForm onPrev={handlePrev} onNext={handleNext} />} />
      <Route path="user" element={<UserStepForm onPrev={handlePrev} onNext={handleNext} />} />
      <Route path="*" element={<Navigate to="" />} />
    </Routes>
  );
};

const CarQuoteForm = () => {
  const dispatch = useDispatch();
  const steps = useSteps();
  const activeStepIdx = useActiveStepIdx(steps);
  const initialValues = useInitialValues();

  const handleSubmit = useCallback((values: FormValues) => {
    const payload: CarFetchPayload = values;
    dispatch(carFetch(payload));
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={steps[activeStepIdx].validationSchema}
      onSubmit={handleSubmit}
    >
      <Form>
        <FormBody activeStepIdx={activeStepIdx} steps={steps} />
      </Form>
    </Formik>
  );
};

export { CarQuoteForm };
